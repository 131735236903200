<template>
  <div
    style="height: 100%; width: 100%;"
    
  >
    <el-container class="admin-container">
      <!--页面头部(上边)-->
      <el-header>
        <div class="admin-logo-container">
          <!-- <img
            class="admin-logo"
            src="../assets/img/admin.jpg"
            alt=""
          > -->
          <span >种子质量追溯安全监管</span>
          
        </div>
        <el-menu
            :default-active="$route.path"
             mode="horizontal"
             background-color="#000d4a"
             text-color="#ffffff"
             active-text-color="#ffffff"
             menu-trigger="hover"
            :router="true"
          >
          
            <el-submenu
              :index="item.id+''"
              v-for="item in menuList"
              :key="item.id"
            >
              <!--          一级菜单模板区-->
              <template slot="title">
                <!--              图标-->
                <i :class="item.icon" />
                <!--              文本-->
                <span>{{ item.subMenuName }}</span>
              </template>
              <!--            二级菜单-->
              <el-menu-item
                :index="subItem.path+''"
                v-for="subItem in item.children"
                :key="subItem.id"
                @click="addTab(subItem.subMenuName, subItem.path)"
              >
                <!--          二级菜单模板区-->
                <template slot="title">
                  <!--              图标-->
                  <i class="el-icon-bangzhu" />
                  <!--              文本-->
                  <span>{{ subItem.subMenuName }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        <div class="right-function-container">
          <!-- <el-button @click="fullscren">
            全屏展示
          </el-button> -->
          <el-button @click="logout">
            退出
          </el-button>
        </div>
      </el-header>
      <!--页面主体部分-->
      <el-container>
        <!--页面侧边栏(左边)-->
        
        <!-- 页面主内容(右边)-->
        <el-main>
          <!-- <el-tabs
            v-model="activeTabas"
            type="card"
            @tab-remove="removeTab"
            @tab-click="tabClick"
          >
            <el-tab-pane
              v-for="item in Tabs"
              :key="item.name"
              :label="item.title"
              :name="item.name"
              :closable="item.isClose"
            >
              <el-container>
                <el-main>
                  <el-card shadow="always">
                    
                   
                  </el-card>
                </el-main>
              </el-container>
            </el-tab-pane>
          </el-tabs> -->
           <router-view  />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'Admin', 
  data () {
    return {
      lastTime: null, // 最后一次点击的时间
      currentTime: null, // 当前点击的时间
      timeOut: 30 * 60 * 1000, // 设置超时时间： 30分钟
      /** tabs标签相关属性 */
      // 当前打开的tabs名称
      activeTabas: '/admin',
      Tabs: [],
      // 左侧菜单数据
      menuList: [
        {
        id:1,
      icon: 'el-icon-s-data',
      subMenuName: '种子大数据',
      children: [{
        id: 2,
        subMenuName: '种子大数据',
        path: '/admin/bigdata'
      }]
    },{
        id:6,
      icon: 'el-icon-s-order',
      subMenuName: '追溯大数据',
      children: [{
        id: 5,
        subMenuName: '追溯大数据',
        path: '/admin/smtz'
      }]
    },
    {
        id:7,
      icon: 'el-icon-s-order',
      subMenuName: '企业管理',
      children: [{
        id: 8,
        subMenuName: '企业注册管理',
        path: '/admin/register'
      }]
    },
    {
        id:3,
      icon: 'el-icon-s-order',
      subMenuName: '品种数据库',
      children: [{
        id: 4,
        subMenuName: '品种数据库',
        path: '/admin/pinzhong'
      }]
    },
    
    ]
    }
  },
  watch: {
    /** 监听,当路由发生变化的时候执行(防止用户手动更换地址导致tabs的面板变动却不切换相应的tabs) */
    '$route.path': function () {
      var iindex = -1
      var jindex = -1
      for (let i = 0; i < this.menuList.length; i++) {
        for (let j = 0; j < this.menuList[i].children.length; j++) {
          if (this.menuList[i].children[j].path == this.$route.path) {
            iindex = i
            jindex = j
            break
          }
        }
      }
      console.log(this.menuList[iindex].children[jindex].subMenuName)
      this.addTab(this.menuList[iindex].children[jindex].subMenuName, this.$route.path)
    }
  },
  created () {
    this.lastTime = new Date().getTime() // 网页第一次打开时，记录当前时间
    // 保证admin中的页面刷新后重置到/admin路由下
    // if (this.$route.path !== '/admin/bigdata') this.$router.push('/admin/bigdata')
    
      var iindex = -1
      var jindex = -1
      for (let i = 0; i < this.menuList.length; i++) {
        for (let j = 0; j < this.menuList[i].children.length; j++) {
          if (this.menuList[i].children[j].path == this.$route.path) {
            iindex = i
            jindex = j
            break
          }
        }
      }
      console.log(this.menuList[iindex].children[jindex].subMenuName)
      this.addTab(this.menuList[iindex].children[jindex].subMenuName, this.$route.path)
    
    let data1 = []
    let data = [{
      icon: 'el-icon-user-solid',
      subMenuName: '品种数据库',
      children: [{
        id: '1-1',
        subMenuName: '品种数据库',
        path: '/admin/pinzhong'
      }]
    },
    
    ]

    // this.menuList = data
  },
  methods: {
    handleOpen (index) {
      console.log(index)
    },
    /** tabs标签相关函数 */
    // tab切换时，动态的切换路由
    tabClick (tab) {
      if (this.$route.path == tab.name) return
      this.$router.push(tab.name)
    },
    addTab (title, name) {
      for (let i = 0; i < this.Tabs.length; i++) {
        if (this.Tabs[i].name == name) {
          this.activeTabas = name
          return
        }
      }
      this.Tabs.push({
        title: title,
        name: name,
        isClose: 'closable'
      })
      this.activeTabas = name
    },
    removeTab (targetName) {
      const tabs = this.Tabs
      let activeName = this.activeTabas
      if (activeName == targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name == targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1]
            if (nextTab) {
              activeName = nextTab.name
            }
          }
        })
      }
      this.activeTabas = activeName
      this.$router.push(activeName)
      this.Tabs = tabs.filter(tab => tab.name !== targetName)
    },
    /** 设置用户为登出状态 */
    async logout () {
      // const formData = { 'token': window.sessionStorage.getItem('token') }
      // // 提交登出请求
      // const result = await this.$http.post('/loginOut', formData)
      // if (result.data.code == 0) {
      // 清除seesion信息
      window.sessionStorage.clear()
      this.$router.push('/login')
      // }
 
    },

    // async checkTimeOut () {
    //   this.currentTime = new Date().getTime() // 记录这次点击的时间
    //   // 如果当前页面不是登录，初始化界面就可以执行是否退出
    //   if (!(this.$route.path == '/login')) {
    //     /** 判断是否因为超时可以退出 */
    //     // 判断时间是否过期（判断上次最后一次点击的时间和这次点击的时间间隔是否大于10分钟）
    //     var isExit = this.currentTime - this.lastTime > this.timeOut
    //     if (isExit) {
    //       isExit = false
    //       // 这里写状态已过期后执行的操作
    //       this.lastTime = new Date().getTime() // 如果在10分钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
    //       this.$router.push('/login')
    //       // 设置用户为登出状态
    //       const formData = {
    //         'token': window.sessionStorage.getItem('token')
    //       }
    //       // 提交登出请求
    //       const result = await this.$http.post('login_out', formData)
    //       if (result.data.code == 0) {
    //         // 清除seesion信息
    //         window.sessionStorage.removeItem('token')
    //       }
    //       this.$message({
    //         message: '长时间未操作，请重新登录',
    //         type: 'warning',
    //         showClose: true,
    //         center: true
    //       })
    //     } else {
    //       /** 判断是否因为用户未登录可以退出 */
    //       // const token = window.sessionStorage.getItem('token')
    //       // if (token == null || token == undefined || token == '') {
    //       //   this.$router.push('/login')
    //       //   this.$message({
    //       //     message: '用户未登录，请重新登录',
    //       //     type: 'warning',
    //       //     showClose: true,
    //       //     center: true
    //       //   })
    //       // }
    //       this.lastTime = new Date().getTime() // 如果在10分钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
    //     }
    //   } else {
    //     this.lastTime = new Date().getTime() // 如果在10分钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
    .admin-container {
        height: 100%;
    }

    .el-main {
        // padding-top: 8px !important;
        padding: 0;
        margin: 0;
    }
	.el-menu-item.is-active {
	// background-color: #3ace04 !important;
	}
	 

    .admin-logo-container {
        // border-color: #5EB9DE;
        // border-right-width: 1px;
        // border-right-style: solid;
    }

    .admin-logo {
        margin-left: 5px;
        height: 55px;
        width: 55px;
        /*自动等比缩放*/
        background-size: 100% 100%;
    }

    .el-header {
        background-color: #000d4a;
        width: 100%;
        color: white;
        font-size: 25px;
        display: flex;
        padding-left: 0;
    }

    .admin-logo-container {
        display: flex;
        align-items: center;

        span {
            margin-left: 15px;
        }
    }

   

    .toggle-button-container {
        width: 180px;
        display: flex;
        justify-content: left;
    }

    // .toggle-button {
    //     width: 50px;
    //     line-height: 60px;
    //     color: #fff;
    //     text-align: center;
    //     cursor: pointer;
    // }

    .right-function-container {
        width: 400px;
        line-height: 50px;
        text-align: right;
        position: absolute;
        right: 15px;
    }
</style>
